import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import AddMobileRepairForm from './components/AddMobileRepairForm';
import UpdateMobileRepairForm from './components/UpdateRepair';
import DeleteMobileRepairForm from './components/DeleteMobileRepairForm';
import ShowAllMobileRepairs from './components/ShowAllMobileRepairs';
import repairReducer from './redux/reducer';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import OutlistPage from './components/Outlistpage';
import LargeSquareButton from './components/LargeSquareButton';
import UnderRepairPage from './components/UnderRepairPage';
import UpdateRepair from './components/UpdateRepair';
import UpdateOutlistRepair from './components/UpdateOutlistRepair';
import UpdateUnderRepair from './components/UpdateUnderRepair';


function App() {
  return (
    
    <Router>
      <div>
        <Navbar />
<div style={{ padding: '20px' }}>
                   <Routes>
            <Route path="/add" element={<AddMobileRepairForm />} />
            <Route path="/update-repair/:modelNumber" element={<UpdateRepair />} />
            <Route path="/update-underrepair-repair/:modelNumber" element={<UpdateUnderRepair />} />
            <Route path="/update-outlist-repair/:modelNumber" element={<UpdateOutlistRepair/>} />
            <Route path="/delete" element={<DeleteMobileRepairForm />} />
            <Route path="/showall" element={<ShowAllMobileRepairs />} />
            <Route path="/outlist" element={<OutlistPage />} />
            <Route path='underrepair' element={<UnderRepairPage/>}/>
          </Routes>
        </div>
      </div>
    </Router>
  
  );
}

export default App;
