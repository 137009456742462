//UpdateUnderRepair
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const UpdateUnderRepair = () => {
  const { modelNumber } = useParams(); // Get the modelNumber from the URL
  const navigate = useNavigate();
  const [repair, setRepair] = useState({
    modelNumber: '',
    companyName: '',
    typeOfRepair: '',
    costOfRepair: '',
    dateOfReceived: '',
  });

  // Load the selected repair from localStorage when the component mounts
  useEffect(() => {
    const underRepairRepairs = JSON.parse(localStorage.getItem('underRepairRepairs')) || [];
    const selectedRepair = underRepairRepairs.find(r => r.modelNumber === modelNumber);
    if (selectedRepair) {
      setRepair(selectedRepair); // Populate the form with the selected repair data
    }
  }, [modelNumber]);

  // Handle input changes
  const handleChange = (e) => {
    setRepair({ ...repair, [e.target.name]: e.target.value });
  };

  // Handle form submission to update the repair
  const handleSubmit = (e) => {
    e.preventDefault();
    const underRepairRepairs = JSON.parse(localStorage.getItem('underRepairRepairs')) || [];
    const updatedRepairs = underRepairRepairs.map(r =>
      r.modelNumber === modelNumber ? repair : r // Update the repair with the new data
    );
    localStorage.setItem('underRepairRepairs', JSON.stringify(updatedRepairs)); // Save the updated list in localStorage
    navigate('/showall'); // Redirect to the under-repair list page
  };

  return (
    <div className="container mt-4">
      <h3>Update Under Repair</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Model Number</label>
          <input
            type="text"
            className="form-control"
            name="modelNumber"
            value={repair.modelNumber}
            onChange={handleChange}
            disabled // Model number should not be editable
          />
        </div>
        <div className="form-group">
          <label>Company Name</label>
          <input
            type="text"
            className="form-control"
            name="companyName"
            value={repair.companyName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Type of Repair</label>
          <input
            type="text"
            className="form-control"
            name="typeOfRepair"
            value={repair.typeOfRepair}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Cost of Repair</label>
          <input
            type="number"
            className="form-control"
            name="costOfRepair"
            value={repair.costOfRepair}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Date of Received</label>
          <input
            type="datetime-local"
            className="form-control"
            name="dateOfReceived"
            value={repair.dateOfReceived}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Update Repair</button>
      </form>
    </div>
  );
};

export default UpdateUnderRepair;
