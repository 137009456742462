import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'; // Import icons

const UnderRepairPage = () => {
  const [underRepairRepairs, setUnderRepairRepairs] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [checkedRepairs, setCheckedRepairs] = useState({});
  const [editingRepair, setEditingRepair] = useState(null); // State for editing
  const [updatedRepair, setUpdatedRepair] = useState({}); // Store updated repair values

  useEffect(() => {
    // Retrieve repairs from localStorage if available  
    const savedRepairs = JSON.parse(localStorage.getItem('underRepairRepairs')) || [];
    setUnderRepairRepairs(savedRepairs);
  }, []);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleString('en-IN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
      .replace(',', '');
  };

  const filteredRepairs = underRepairRepairs.filter((repair) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      repair.modelNumber.toLowerCase().includes(searchLower) ||
      repair.companyName.toLowerCase().includes(searchLower) ||
      repair.typeOfRepair.toLowerCase().includes(searchLower) ||
      repair.costOfRepair.toString().toLowerCase().includes(searchLower) ||
      repair.dateOfReceived.toLowerCase().includes(searchLower)
    );
  });

  const handleCheckboxChange = (modelNumber) => {
    setCheckedRepairs((prev) => ({
      ...prev,
      [modelNumber]: !prev[modelNumber],
    }));
  };

  const handleDeleteSelected = () => {
    const repairsToDelete = Object.keys(checkedRepairs).filter(modelNumber => checkedRepairs[modelNumber]);
    const updatedRepairs = underRepairRepairs.filter(repair => !repairsToDelete.includes(repair.modelNumber));
    setUnderRepairRepairs(updatedRepairs);
    localStorage.setItem('underRepairRepairs', JSON.stringify(updatedRepairs));
    setCheckedRepairs({});
  };

  const handleEditRepair = (repair) => {
    setEditingRepair(repair);
    setUpdatedRepair(repair); // Pre-fill the form with the selected repair's details
  };

  const handleInputChange = (e) => {
    setUpdatedRepair({
      ...updatedRepair,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveEdit = () => {
    const updatedRepairs = underRepairRepairs.map((repair) =>
      repair.modelNumber === updatedRepair.modelNumber ? updatedRepair : repair
    );
    setUnderRepairRepairs(updatedRepairs);
    localStorage.setItem('underRepairRepairs', JSON.stringify(updatedRepairs)); // Save the updated repairs to localStorage
    setEditingRepair(null); // Close the edit form after saving
  };

  return (
    <div className="container mt-4">
      <h3 className="text-center">Under Repair List</h3>

      {/* Search Input */}
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          placeholder="Search by any field"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>Select</th> {/* Checkbox header */}
              <th>Model/Mobile Number</th>
              <th>Company Name</th>
              <th>Type of Repair</th>
              <th>Cost of Repair</th>
              <th>Date and Time Received</th>
              <th>Actions</th> {/* New column for update button */ }
            </tr>
          </thead>
          <tbody>
            {filteredRepairs.length > 0 ? (
              filteredRepairs.map((repair, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={!!checkedRepairs[repair.modelNumber]} 
                      onChange={() => handleCheckboxChange(repair.modelNumber)}
                    />
                  </td>
                  <td>{repair.modelNumber}</td>
                  <td>{repair.companyName}</td>
                  <td>{repair.typeOfRepair}</td>
                  <td>{repair.costOfRepair}</td>
                  <td>{formatDateTime(repair.dateOfReceived)}</td>
                  <td>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="text-primary mx-2"
                      onClick={() => handleEditRepair(repair)} // Open inline edit form
                      style={{ cursor: 'pointer' }}
                    />
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="text-danger mx-2"
                      onClick={handleDeleteSelected}
                      style={{ cursor: 'pointer' }}
                      disabled={!Object.values(checkedRepairs).some(Boolean)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">No repairs under repair</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Inline Edit Form */}
      {editingRepair && (
        <div className="edit-form mt-4">
          <h5>Edit Repair</h5>
          <div className="form-group">
            <label>Model Number:</label>
            <input
              type="text"
              className="form-control"
              name="modelNumber"
              value={updatedRepair.modelNumber}
              onChange={handleInputChange}
              disabled // Model Number should not be editable
            />
          </div>
          <div className="form-group">
            <label>Company Name:</label>
            <input
              type="text"
              className="form-control"
              name="companyName"
              value={updatedRepair.companyName}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Type of Repair:</label>
            <input
              type="text"
              className="form-control"
              name="typeOfRepair"
              value={updatedRepair.typeOfRepair}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Cost of Repair:</label>
            <input
              type="number"
              className="form-control"
              name="costOfRepair"
              value={updatedRepair.costOfRepair}
              onChange={handleInputChange}
            />
          </div>
          <div className="text-center">
            <button className="btn btn-success mx-1" onClick={handleSaveEdit}>
              Save Changes
            </button>
            <button className="btn btn-secondary mx-1" onClick={() => setEditingRepair(null)}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UnderRepairPage;
