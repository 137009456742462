import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateRepair } from '../redux/actions'; // Make sure this action exists in your redux actions

const UpdateRepair = () => {
  const { modelNumber } = useParams(); // Get modelNumber from URL
  const location = useLocation(); // Get the repair data passed through state
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Destructure the repair data from location.state
  const repairToUpdate = location.state?.repair;

  // Create local state to handle form fields
  const [repairData, setRepairData] = useState({
    modelNumber: repairToUpdate?.modelNumber || '',
    companyName: repairToUpdate?.companyName || '',
    typeOfRepair: repairToUpdate?.typeOfRepair || '',
    costOfRepair: repairToUpdate?.costOfRepair || '',
    dateOfReceived: repairToUpdate?.dateOfReceived || '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRepairData({
      ...repairData,
      [name]: value,
    });
  };

  const handleUpdate = () => {
    // Dispatch the updateRepair action
    dispatch(updateRepair(repairData));
    navigate('/'); // After update, navigate back to the main page
  };

  return (
    <div className="container mt-4">
      <h3>Update Repair</h3>
      <form>
        <div className="form-group">
          <label>Model Number</label>
          <input
            type="text"
            name="modelNumber"
            className="form-control"
            value={repairData.modelNumber}
            onChange={handleInputChange}
            disabled // Model number should not be changed
          />
        </div>
        <div className="form-group">
          <label>Company Name</label>
          <input
            type="text"
            name="companyName"
            className="form-control"
            value={repairData.companyName}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Type of Repair</label>
          <input
            type="text"
            name="typeOfRepair"
            className="form-control"
            value={repairData.typeOfRepair}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Cost of Repair</label>
          <input
            type="number"
            name="costOfRepair"
            className="form-control"
            value={repairData.costOfRepair}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Date of Received</label>
          <input
            type="text"
            name="dateOfReceived"
            className="form-control"
            value={repairData.dateOfReceived}
            onChange={handleInputChange}
          />
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleUpdate}
        >
          Update Repair
        </button>
      </form>
    </div>
  );
};

export default UpdateRepair;
