import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const UpdateOutlistRepair = () => {
  const { modelNumber } = useParams(); // Get the modelNumber from the route
  const navigate = useNavigate();
  const [repair, setRepair] = useState({
    modelNumber: '',
    companyName: '',
    typeOfRepair: '',
    costOfRepair: '',
    dateOfReceived: '',
  });

  useEffect(() => {
    const outlistRepairs = JSON.parse(localStorage.getItem('outlistRepairs')) || [];
    const selectedRepair = outlistRepairs.find(r => r.modelNumber === modelNumber);
    if (selectedRepair) {
      setRepair(selectedRepair); // Populate form with selected repair details
    }
  }, [modelNumber]);

  const handleChange = (e) => {
    setRepair({ ...repair, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const outlistRepairs = JSON.parse(localStorage.getItem('outlistRepairs')) || [];
    const updatedRepairs = outlistRepairs.map(r =>
      r.modelNumber === modelNumber ? repair : r
    );
    localStorage.setItem('outlistRepairs', JSON.stringify(updatedRepairs)); // Update localStorage
    navigate('/outlist'); // Redirect back to the outlist page
  };

  return (
    <div className="container mt-4">
      <h3>Update Outlist Repair</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Model Number</label>
          <input
            type="text"
            className="form-control"
            name="modelNumber"
            value={repair.modelNumber}
            onChange={handleChange}
            disabled // Model number should not be editable
          />
        </div>
        <div className="form-group">
          <label>Company Name</label>
          <input
            type="text"
            className="form-control"
            name="companyName"
            value={repair.companyName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Type of Repair</label>
          <input
            type="text"
            className="form-control"
            name="typeOfRepair"
            value={repair.typeOfRepair}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Cost of Repair</label>
          <input
            type="number"
            className="form-control"
            name="costOfRepair"
            value={repair.costOfRepair}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Date of Received</label>
          <input
            type="datetime-local"
            className="form-control"
            name="dateOfReceived"
            value={repair.dateOfReceived}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Update Repair</button>
      </form>
    </div>
  );
};

export default UpdateOutlistRepair;
