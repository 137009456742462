// DeleteMobileRepairForm.js
import React from 'react';

const DeleteMobileRepairForm = () => {
  return (
    <div>
      <h3>Delete Mobile Repair</h3>
      {/* Form for deleting mobile repair details */}
    </div>
  );
};

export default DeleteMobileRepairForm;