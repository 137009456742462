import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { deleteRepairs, updateRepair } from '../redux/actions'; // Assuming you have these actions

const ShowAllMobileRepairs = () => {
  const repairs = useSelector((state) => state.repairs.repairs || []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedRepairs, setSelectedRepairs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [editingRepair, setEditingRepair] = useState(null);
  const [updatedRepair, setUpdatedRepair] = useState({});

  const searchedRepairs = repairs.filter((repair) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      repair.modelNumber.toLowerCase().includes(searchLower) ||
      repair.companyName.toLowerCase().includes(searchLower) ||
      repair.typeOfRepair.toLowerCase().includes(searchLower) ||
      repair.costOfRepair.toString().toLowerCase().includes(searchLower) ||
      repair.dateOfReceived.toLowerCase().includes(searchLower)
    );
  });

  // Calculate the total cost of repair
  const totalCostOfRepairs = searchedRepairs.reduce((acc, repair) => acc + parseFloat(repair.costOfRepair || 0), 0);

  const handleCheckboxChange = (repair) => {
    if (selectedRepairs.includes(repair)) {
      setSelectedRepairs(selectedRepairs.filter((r) => r !== repair));
    } else {
      setSelectedRepairs([...selectedRepairs, repair]);
    }
  };

  const handleDeleteSelected = () => {
    const repairsToDelete = selectedRepairs.map((repair) => repair.modelNumber);
    dispatch(deleteRepairs(repairsToDelete)); // Dispatch delete action
    setSelectedRepairs([]); // Clear selected repairs
  };

  const handleEditRepair = (repair) => {
    setEditingRepair(repair); // Set repair to be edited
    setUpdatedRepair(repair); // Copy repair details for editing
  };

  const handleSaveEdit = () => {
    dispatch(updateRepair(updatedRepair)); // Dispatch update action
    setEditingRepair(null); // Reset editing state
  };

  const handleInputChange = (e) => {
    setUpdatedRepair({
      ...updatedRepair,
      [e.target.name]: e.target.value,
    });
  };

  const handleMoveSelectedToOutlist = () => {
    const existingRepairs = JSON.parse(localStorage.getItem('outlistRepairs')) || [];
    const updatedRepairs = [
      ...existingRepairs,
      ...selectedRepairs.filter(
        (selected) => !existingRepairs.some((existing) => existing.modelNumber === selected.modelNumber)
      ),
    ];
    localStorage.setItem('outlistRepairs', JSON.stringify(updatedRepairs));
    navigate('/outlist');
  };

  const handleMoveSelectedToUnderRepair = () => {
    const existingRepairs = JSON.parse(localStorage.getItem('underRepairRepairs')) || [];
    const updatedRepairs = [
      ...existingRepairs,
      ...selectedRepairs.filter(
        (selected) => !existingRepairs.some((existing) => existing.modelNumber === selected.modelNumber)
      ),
    ];
    localStorage.setItem('underRepairRepairs', JSON.stringify(updatedRepairs));
    navigate('/underrepair');
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleString('en-IN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
      .replace(',', '');
  };

  // Updated function for better formatted Notepad export
  const generateNotepadContent = () => {
    let content = "Mobile Repairs List:\n\n";
    content += `Model/Mobile Number    Company Name        Type of Repair          Cost of Repair    Date and Time Received\n`;
    content += `--------------------------------------------------------------------------------------------\n`;
  
    searchedRepairs.forEach((repair) => {
      // Aligning values manually using padding
      const modelNumber = repair.modelNumber.padEnd(20);
      const companyName = repair.companyName.padEnd(18);
      const typeOfRepair = repair.typeOfRepair.padEnd(25);
      const costOfRepair = repair.costOfRepair ? repair.costOfRepair.toString().padEnd(15) : "undefined".padEnd(15);
      const dateOfReceived = formatDateTime(repair.dateOfReceived).padEnd(25);
  
      content += `${modelNumber}${companyName}${typeOfRepair}${costOfRepair}${dateOfReceived}\n`;
    });
  
    return content;
  };

  const exportToNotepad = () => {
    const element = document.createElement("a");
    const file = new Blob([generateNotepadContent()], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "mobile_repairs.txt";
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h5>Total Cost for Repairs: ${totalCostOfRepairs.toFixed(2)}</h5>

        <button className="btn btn-info" onClick={exportToNotepad}>
          <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Export
        </button>
      </div>

      <div className="form-group mt-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by any field"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>Select</th>
              <th>Model/Mobile Number</th>
              <th>Company Name</th>
              <th>Type of Repair</th>
              <th>Cost of Repair</th>
              <th>Date and Time Received</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {searchedRepairs.length > 0 ? (
              searchedRepairs.map((repair, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRepairs.includes(repair)}
                      onChange={() => handleCheckboxChange(repair)}
                    />
                  </td>
                  <td>{repair.modelNumber}</td>
                  <td>{repair.companyName}</td>
                  <td>{repair.typeOfRepair}</td>
                  <td>{repair.costOfRepair}</td>
                  <td>{formatDateTime(repair.dateOfReceived)}</td>
                  <td>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="text-primary mx-2"
                      onClick={() => handleEditRepair(repair)}
                      style={{ cursor: 'pointer' }}
                    />
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="text-danger mx-2"
                      onClick={handleDeleteSelected}
                      style={{ cursor: 'pointer' }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">No repairs available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="text-center">
        <button
          className="btn btn-info mx-1"
          onClick={handleMoveSelectedToOutlist}
          disabled={selectedRepairs.length === 0}
        >
          Move Selected to Outlist
        </button>
        <button
          className="btn btn-warning mx-1"
          onClick={handleMoveSelectedToUnderRepair}
          disabled={selectedRepairs.length === 0}
        >
          Move Selected to Under Repair
        </button>
      </div>

      {editingRepair && (
        <div className="edit-form mt-4">
          <h5>Edit Repair</h5>
          <div className="form-group">
            <label>Model Number:</label>
            <input
              type="text"
              className="form-control"
              name="modelNumber"
              value={updatedRepair.modelNumber}
              onChange={handleInputChange}
              disabled
            />
          </div>
          <div className="form-group">
            <label>Company Name:</label>
            <input
              type="text"
              className="form-control"
              name="companyName"
              value={updatedRepair.companyName}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Type of Repair:</label>
            <input
              type="text"
              className="form-control"
              name="typeOfRepair"
              value={updatedRepair.typeOfRepair}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Cost of Repair:</label>
            <input
              type="number"
              className="form-control"
              name="costOfRepair"
              value={updatedRepair.costOfRepair}
              onChange={handleInputChange}
            />
          </div>
          <div className="text-center">
            <button className="btn btn-success" onClick={handleSaveEdit}>
              Save Changes
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowAllMobileRepairs;
